import { Container, Grid } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BusyIndicator from '../../Components/BusyIndicator/BusyIndicator';
import Content from '../../Components/Content/Content';
import TokenContext from '../../Contexts/TokenContext';
import Article from '../../Types/Article';
import EanCode from '../../Types/EanCode';
import SynonymList from '../../Components/SynonymList/SynonymList';
import ArticleCard from '../../Components/ArticleCard/ArticleCard';
import EanCodeList from '../../Components/EanCodeList/EanCodeList';

function Edit()
{
    let tokenContext = useContext(TokenContext);
    let [article, setArticle] = useState<Article>();
    let [loaded, setLoaded] = useState(false);
    const { id } = useParams();

    useEffect(() => {
        if (!loaded) {
            axios.get('/api/articles/' + id, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response) => {
                if (response.status === 200) {
                    setArticle(response.data);
                    setLoaded(true);
                }
            });
        }
    }, [loaded, id, tokenContext]);

    return <BusyIndicator loaded={(loaded || !!article)}>
        <Content>
            <Container maxWidth={'xl'} sx={{paddingTop: 5}}>
                <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                        {article && <ArticleCard article={article} />}
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        {article && <SynonymList articleId={article.id} />}
                    </Grid>
                    <Grid item xs={12} lg={3}>
                        {article && <EanCodeList articleId={article.id} />}
                    </Grid>
                </Grid>
            </Container>
        </Content>
    </BusyIndicator>
}

export default Edit;
