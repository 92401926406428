import { Divider, Grid, IconButton } from "@mui/material";
import ReceiptPosition from "../../Types/ReceiptPosition";
import React, { useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import TokenContext from "../../Contexts/TokenContext";
import BusyIndicator from "../BusyIndicator/BusyIndicator";
import SnackBarMessageContext from "../../Contexts/SnackBarMessageContext";
import useFormatter from "../../Hooks/useFormatter";

type Props = {
    positions: ReceiptPosition[];
};

const ReceiptPositions = ({ positions }: Props) => {
    const formatter = useFormatter();
    let tokenContext = useContext(TokenContext);
    let snackBarContext = useContext(SnackBarMessageContext);
    const [totalAmount, setTotalAmount] = useState<number | null>(null);
    const [receiptPositions, setReceiptPositions] = useState<ReceiptPosition[]>(positions);
    let [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        let totalPrice = 0;
        positions.forEach((value: ReceiptPosition) => {
            const isDeposit = value.article.name === "Leergut";
            if (!isDeposit) {
                totalPrice = Number(totalPrice) + (Number(value.quantity) * Number(value.price) + Number(value.deposit));
            } else {
                totalPrice = Number(totalPrice) + Number(Number(value.price) * -1);
            }
        });

        setTotalAmount(totalPrice);
        setLoaded(true);
    }, []);

    const deletePosition = (position: ReceiptPosition) => {
        setLoaded(false);
        axios
            .delete("/api/receipts/" + position.receipt_id + "/positions/" + position.id, {
                headers: {
                    Authorization: "Bearer " + tokenContext.token,
                },
            })
            .then((response) => {
                if (response.status === 204) {
                    setReceiptPositions(receiptPositions.filter((item: ReceiptPosition) => item.id !== position.id));
                    snackBarContext.setSeverity("success");
                    snackBarContext.setMessage("Die Belegposition wurde gelöscht.");
                } else {
                    snackBarContext.setSeverity("error");
                    snackBarContext.setMessage("Die Belegposition konnte nicht gelöscht werden.");
                }
                snackBarContext.openSnackBar();
            })
            .catch(() => {
                snackBarContext.setSeverity("error");
                snackBarContext.setMessage("Die Belegposition konnte nicht gelöscht werden.");
                snackBarContext.openSnackBar();
            })
            .finally(() => {
                setLoaded(true);
            });
    };

    return (
        <Grid container>
            <BusyIndicator loaded={loaded}>
                {receiptPositions.map((position: ReceiptPosition, index: number) => {
                    const isDeposit = position.article.name === "Leergut";
                    return (
                        <React.Fragment key={index}>
                            <Grid
                                item
                                xs={3}
                                sm={1}>
                                <IconButton
                                    size="small"
                                    onClick={() => deletePosition(position)}
                                    edge="end"
                                    aria-label="comments">
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                            <Grid
                                item
                                xs={9}
                                sm={3}>
                                {position.article.name}
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                sm={2}>
                                Menge: {position.quantity}
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                sm={3}
                                textAlign={"right"}>
                                {!isDeposit && formatter.format(position.price)}
                            </Grid>
                            <Grid
                                item
                                xs={4}
                                sm={3}
                                textAlign={"right"}>
                                {!isDeposit ? formatter.format(position.price * position.quantity) : formatter.format(position.price * -1)}
                            </Grid>
                            {!isDeposit && position.deposit && position.deposit !== 0.0 && (
                                <Grid
                                    item
                                    xs={12}
                                    textAlign={"right"}>
                                    Pfand: {formatter.format(position.deposit)}
                                </Grid>
                            )}
                            <Grid
                                item
                                xs={12}
                                sx={{ my: 2 }}>
                                <Divider />
                            </Grid>
                        </React.Fragment>
                    );
                })}
            </BusyIndicator>
            <Grid
                item
                xs={6}
                sx={{
                    fontWeight: "bold",
                    borderTop: "thin solid black",
                    paddingTop: 2,
                }}>
                Gesamt:
            </Grid>
            <Grid
                item
                xs={6}
                textAlign={"right"}
                sx={{
                    fontWeight: "bold",
                    borderTop: "thin solid black",
                    paddingTop: 2,
                }}>
                {formatter.format(totalAmount ?? 0)}
            </Grid>
        </Grid>
    );
};

export default ReceiptPositions;
