import axios, { AxiosError, AxiosResponse } from 'axios';
import TokenContext from '../Contexts/TokenContext';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Box, Button } from '@mui/material';
import InputField from '../Components/InputField/InputField';
import SnackBarMessageContext from '../Contexts/SnackBarMessageContext';

function LoginForm() {
    let navigate = useNavigate();
    let tokenContext = useContext(TokenContext);
    let snackBarContext = useContext(SnackBarMessageContext);

    let [errorMessage, setErrorMessage] = useState<string|null>(null);
    let [username, setUsername] = useState<string>('');
    let [password, setPassword] = useState<string>('');

    const handleLogin = () => {
        let credentials = {
            username,
            password,
        }
        axios.post('/api/login', credentials).then((response: AxiosResponse) => {
            if (response.status === 200) {
                var token = response.data.token ?? null;
                if (tokenContext.setToken && token !== null) {
                    tokenContext.setToken(token);
                    snackBarContext.setSeverity("success");
                    snackBarContext.setMessage("Du wurdest erfolgreich eingeloggt.");
                    snackBarContext.openSnackBar();
                    navigate('/');
                }
            }

            setErrorMessage("Upsi! Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche den Login erneut.");
        }).catch((error: AxiosError) => {
            if (error.response?.status === 401) {
                setErrorMessage("Leider konnten wir mit dieser Username-Passwort Kombination keinen User finden.");
            } else {
                setErrorMessage(error.message);
            }
        });
    }

    return <>
        {errorMessage && (
            <Alert severity="error">{errorMessage}</Alert>
        )}
        <Box sx={{ mt: 1 }}>
            <InputField
                id="username"
                label="Username:"
                value={username}
                style={{ my: 1 }}
                setValue={(value: string) => setUsername(value)} />
            <InputField
                id="password"
                label="Password:"
                type={'password'}
                value={password}
                style={{ my: 1 }}
                setValue={(value: string) => setPassword(value)} />
            <Button
                onClick={handleLogin}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }} >
                Einloggen
            </Button>
        </Box>
    </>;
}

export default LoginForm;
