import { Grid, LinearProgress } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import TokenContext from '../../Contexts/TokenContext';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Content from '../../Components/Content/Content';
import Headline from '../../Components/Headline/Headline';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate } from 'react-router-dom';
import { Container } from '@mui/system';
import ResponsiveButton from '../../Components/ResponsiveButton/ResponsiveButton';
import BusyIndicator from '../../Components/BusyIndicator/BusyIndicator';

function Index() {
    let navigate = useNavigate();
    let tokenContext = useContext(TokenContext);
    let [articles, setArticles] = useState([]);
    let [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (tokenContext?.token && !loaded) {
            axios.get('/api/articles', {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response) => {
                if (response.status === 200) {
                    setArticles(response.data);
                    setLoaded(true);
                }
            })
        }
    }, [loaded, tokenContext]);

    const columns: GridColDef[] = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Name', width: 350 },
        { field: 'category', headerName: 'Kategorie', width: 350, renderCell: (params) => {
            return params.row.category?.name ?? '';
        }},
        { field: 'brand', headerName: 'Marke', width: 350, renderCell: (params) => {
            return params.row.brand?.name ?? '';
        }}
    ];

    return <BusyIndicator loaded={loaded}>
        <Content>
            <Container maxWidth={'xl'} sx={{paddingTop: 5}}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Headline size='h5'>Artikel</Headline>
                    </Grid>
                    <Grid item xs={6} textAlign={'right'}>
                        <ResponsiveButton
                            value={'Neue Artikel'}
                            icon={<AddIcon />}
                            onClick={() => navigate('/articles/add')} />
                    </Grid>
                </Grid>
                <div style={{ height: 800, marginTop: 20, width: '100%' }}>
                    {loaded ? <DataGrid
                        rows={articles}
                        columns={columns}
                        onCellClick={(params) => navigate('/articles/' + params.row.id)}
                    /> : <LinearProgress />}
                </div>
            </Container>
        </Content>
    </BusyIndicator>
}

export default Index;
