import { Card, CardContent, Grid } from '@mui/material';
import moment from 'moment';
import Receipt from "../../Types/Receipt"
import Headline from '../../Components/Headline/Headline';
import Subtitle from '../../Components/Subtitle/Subtitle';

type Props = {
    receipt: Receipt;
}

const ReceiptCard = ({ receipt }: Props) => {
    return <Card>
        <CardContent>
            <Grid container>
                <Grid item xs={6}>
                    <Headline size="h5">Beleg #{receipt?.receipt_number}</Headline>
                </Grid>
                <Grid item xs={6} textAlign={'end'}>
                    <Subtitle size="subtitle1">
                        {moment(receipt?.receipt_date).format('DD.MM.YYYY - HH:mm') + ' Uhr'}
                    </Subtitle>
                </Grid>
                <Grid item xs={12}>
                    <Subtitle size="subtitle2" secondary>
                        {receipt?.shop?.name}
                    </Subtitle>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
}

export default ReceiptCard;
