import { Alert, Autocomplete, Button, TextField } from '@mui/material';
import { Box } from '@mui/system';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Components/InputField/InputField';
import TokenContext from '../Contexts/TokenContext';
import Brand from '../Types/Brand';
import Category from '../Types/Category';

function ArticleForm()
{
    const navigate = useNavigate();
    const tokenContext = useContext(TokenContext);
    const [errorMessage, setErrorMessage] = useState<string|null>(null);
    const [name, setName] = useState<string|null>(null);
    const [categories, setCategories] = useState<Category[]>([]);
    const [brands, setBrands] = useState<Brand[]>([]);
    const [selectedCategory, setSelectedCategory] = useState<Category|null>(null);
    const [selectedBrand, setSelectedBrand] = useState<Category|null>(null);

    useEffect(() => {
        axios.get('/api/categories', {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response: AxiosResponse) => {
            if (response?.status === 200) {
                setCategories(response.data);
            } else {
                setErrorMessage("Upsi! Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche den Login erneut.");
            }
        }).catch((error: AxiosError) => {
            setErrorMessage(error.message);
        });

        axios.get('/api/brands', {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response: AxiosResponse) => {
            if (response?.status === 200) {
                setBrands(response.data);
            } else {
                setErrorMessage("Upsi! Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche den Login erneut.");
            }
        }).catch((error: AxiosError) => {
            setErrorMessage(error.message);
        });
    }, [tokenContext]);

    const createArticle = () => {
        if (tokenContext?.token) {
            const data = { name, category_id: selectedCategory?.id, brand_id: selectedBrand?.id };

            axios.post('/api/articles', data, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response: AxiosResponse) => {
                if (response?.status === 201) {
                    if (response.data.id) {
                        navigate('/articles');
                    }
                }

                setErrorMessage("Upsi! Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche den Login erneut.");
            }).catch((error: AxiosError) => {
                setErrorMessage(error.message);
            });
        }
    }

    return <>
        {errorMessage && (
            <Alert severity="error">{errorMessage}</Alert>
        )}
        <Box sx={{ mt: 1 }}>
            <InputField
                id='name'
                label='Name:'
                value={name ?? ''}
                style={{ my: 1 }}
                setValue={(value) => setName(value)}
            />
            <Autocomplete
                id="category_id"
                options={categories ?? []}
                getOptionLabel={(option) => option.name }
                onChange={(event, value) => {
                    setSelectedCategory(value ? value : null);
                }}
                renderInput={(params) => <TextField style={{width: '100%', marginTop: 15}} {...params} label="Kategorie" />}
            />
            <Autocomplete
                id="brand_id"
                options={brands ?? []}
                getOptionLabel={(option) => option.name }
                onChange={(event, value) => {
                    setSelectedBrand(value ? value : null);
                }}
                renderInput={(params) => <TextField style={{width: '100%', marginTop: 15}} {...params} label="Marke" />}
            />
            <Button
                onClick={createArticle}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                Anlegen
            </Button>
        </Box>
    </>
}

export default ArticleForm;
