import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';

type Props = {
    children: string|ReactNode;
    size: 'subtitle1'|'subtitle2';
    secondary?: boolean;
}

function Subtitle({children, size, secondary }: Props) {
    return <Typography component={'h5'} variant={size} color={`text.${secondary ? 'secondary' : 'primary'}`}>
        {children}
    </Typography>
}

export default Subtitle;
