import { Alert, Button } from '@mui/material';
import { Box } from '@mui/system';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Components/InputField/InputField';
import TokenContext from '../Contexts/TokenContext';
import Category from '../Types/Category';

type Props = {
    category?: Category;
}

function CategoryForm({ category } : Props)
{
    let navigate = useNavigate();
    let tokenContext = useContext(TokenContext);
    let [errorMessage, setErrorMessage] = useState<string|null>(null);
    let [name, setName] = useState<string>("");
    let [loading, setLoading] = useState<boolean>(true);

    const createCategory = () => {
        if (tokenContext?.token) {
            const data = { name }

            axios.post('/api/categories', data, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response: AxiosResponse) => {
                if (response?.status === 201) {
                    if (response.data.id) {
                        navigate('/categories');
                    }
                }

                setErrorMessage("Upsi! Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche den Login erneut.");
            }).catch((error: AxiosError) => {
                setErrorMessage(error.message);
            });
        }
    }

    const updateCategory = () => {
        if (tokenContext?.token && category) {
            const data = { name }

            axios.put(`/api/categories/${category.id}`, data, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response: AxiosResponse) => {
                if (response?.status === 200) {
                    if (response.data.id) {
                        navigate('/categories');
                    }
                }

                setErrorMessage("Upsi! Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche den Login erneut.");
            }).catch((error: AxiosError) => {
                setErrorMessage(error.message);
            });
        }
    }

    useEffect(() => {
        if (category) {
            setName(category.name);
        }
        setLoading(false);
    }, [category]);

    return loading ? <></> : <>
        {errorMessage && (
            <Alert severity="error">{errorMessage}</Alert>
        )}
        <Box sx={{ mt: 1 }}>
            <InputField
                id='name'
                label='Name:'
                value={name}
                style={{ my: 1 }}
                setValue={setName}
            />
            <Button
                onClick={category ? updateCategory : createCategory}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                {category ? <>Speichern</> : <>Anlegen</>}
            </Button>
        </Box>
    </>
}

export default CategoryForm;
