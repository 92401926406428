import { useContext } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Link from '@mui/material/Link';
import AppTitle from '../AppTitle/AppTitle';
import BurgerMenu from '../BurgerMenu/BurgerMenu';
import TokenContext from '../../Contexts/TokenContext';
import { useNavigate } from 'react-router-dom';

function AppBar() {
    let tokenContext = useContext(TokenContext);
    const navigate = useNavigate();

    return (
        <Box sx={{ flexGrow: 1, marginBottom: 5 }}>
            <MuiAppBar position="fixed">
                <Toolbar>
                    <BurgerMenu />
                    <AppTitle appName='Nomtastisch' />
                    {tokenContext?.token === null && (
                        <Link href="#"
                              onClick={() => navigate('/login')}
                              variant="body1"
                              underline='none'
                              color="#FFF">
                            Login
                        </Link>
                    )}
                </Toolbar>
            </MuiAppBar>
        </Box>
    )
}

export default AppBar;
