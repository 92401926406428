import { Alert, Button } from '@mui/material';
import { Box } from '@mui/system';
import axios, { AxiosError, AxiosResponse } from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import InputField from '../Components/InputField/InputField';
import TokenContext from '../Contexts/TokenContext';
import Brand from '../Types/Brand';

type Props = {
    brand?: Brand;
}

function BrandForm({ brand }: Props)
{
    let navigate = useNavigate();
    let tokenContext = useContext(TokenContext);
    let [errorMessage, setErrorMessage] = useState<string|null>(null);
    let [name, setName] = useState<string>("");
    let [notes, setNotes] = useState<string>("");
    let [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (brand) {
            setName(brand.name);
            setNotes(brand.notes);
        }

        setLoading(false);
    }, [brand]);

    const createBrand = () => {
        if (tokenContext?.token) {
            const data = { name, notes }

            axios.post('/api/brands', data, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response: AxiosResponse) => {
                if (response?.status === 201) {
                    if (response.data.id) {
                        navigate('/brands');
                    }
                }

                setErrorMessage("Upsi! Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche den Login erneut.");
            }).catch((error: AxiosError) => {
                setErrorMessage(error.message);
            });
        }
    }

    const updateBrand = () => {
        if (tokenContext?.token && brand) {
            const data = { name, notes }

            axios.put(`/api/brands/${brand.id}`, data, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response: AxiosResponse) => {
                if (response?.status === 200) {
                    if (response.data.id) {
                        navigate('/brands');
                    }
                }

                setErrorMessage("Upsi! Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche den Login erneut.");
            }).catch((error: AxiosError) => {
                setErrorMessage(error.message);
            });
        }
    }

    return loading ? <></> : <>
        {errorMessage && (
            <Alert severity="error">{errorMessage}</Alert>
        )}
        <Box sx={{ mt: 1 }}>
            <InputField
                outlined
                id='name'
                label='Name:'
                value={name ?? ''}
                style={{ my: 1 }}
                setValue={(value) => setName(value)}
            />
            <InputField
                outlined
                id='name'
                label='Notizen:'
                value={notes ?? ''}
                style={{ my: 1 }}
                textarea
                setValue={(value) => setNotes(value)}
            />
            <Button
                onClick={brand ? updateBrand : createBrand}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                {brand ? "Speichern" : "Anlegen" }
            </Button>
        </Box>
    </>
}

export default BrandForm;
