import { ReactNode } from 'react';
import { Button, IconButton, useMediaQuery, useTheme } from "@mui/material";

type Props = {
    onClick: () => void;
    value: string;
    icon?: ReactNode;
}

const ResponsiveButton = ({ onClick, value, icon } : Props) =>
{
    const theme = useTheme();
    const lowerThanSM = useMediaQuery(theme.breakpoints.down('sm'));

    return !lowerThanSM
        ? <Button
            variant="contained"
            startIcon={icon}
            onClick={onClick}>
                {value}
        </Button>
        : <IconButton color='primary' onClick={onClick}>
            {icon}
        </IconButton>;
}

export default ResponsiveButton;
