import { useContext, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer } from '@mui/material';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ShopIcon from '@mui/icons-material/Storefront';
import FactoryIcon from '@mui/icons-material/Factory';
import ArticleIcon from '@mui/icons-material/Article';
import CategoryIcon from '@mui/icons-material/Category';
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import TokenContext from '../../Contexts/TokenContext';

function BurgerMenu() {
    let navigate = useNavigate();
    let tokenContext = useContext(TokenContext);

    let [isOpen, setOpen] = useState(false);
    return (
        <>
            <IconButton
                onClick={() => setOpen(true)}
                size="large"
                edge="start"
                color="inherit"
                aria-label="menu"
                sx={{ mr: 2 }}
            >
                <MenuIcon />
            </IconButton>

            <SwipeableDrawer anchor='left' open={isOpen} onOpen={() => setOpen(true)} onClose={() => setOpen(false)}>
                <Box sx={{ width: 250 }} role="presentation">
                    <List>
                        {tokenContext?.token !== null && (
                            <>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {
                                        setOpen(false);
                                        navigate('/receipts');
                                    }}>
                                        <ListItemIcon>
                                            <ArticleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Belege" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {
                                        setOpen(false);
                                        navigate('/articles');
                                    }}>
                                        <ListItemIcon>
                                            <ShoppingBasketIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Artikel" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {
                                        setOpen(false);
                                        navigate('/shops');
                                    }}>
                                        <ListItemIcon>
                                            <ShopIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Shops" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {
                                        setOpen(false);
                                        navigate('/brands');
                                    }}>
                                        <ListItemIcon>
                                            <FactoryIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Marken" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {
                                        setOpen(false);
                                        navigate('/categories');
                                    }}>
                                        <ListItemIcon>
                                            <CategoryIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Kategorien" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {
                                        setOpen(false);
                                        navigate('/logout');
                                    }}>
                                        <ListItemIcon>
                                            <LogoutIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Ausloggen" />
                                    </ListItemButton>
                                </ListItem>
                                <Divider />
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {
                                        setOpen(false);
                                        //navigate('/receipts');
                                    }}>
                                        <ListItemText primary={''} secondary="Not implemented yet" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {
                                        setOpen(false);
                                        //navigate('/receipts');
                                    }}>
                                        <ListItemIcon>
                                            <ArticleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Meine Rezepte" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {
                                        setOpen(false);
                                        //navigate('/receipts');
                                    }}>
                                        <ListItemIcon>
                                            <ArticleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Wochenplan" />
                                    </ListItemButton>
                                </ListItem>
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => {
                                        setOpen(false);
                                        //navigate('/receipts');
                                    }}>
                                        <ListItemIcon>
                                            <ArticleIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Einkaufszettel" />
                                    </ListItemButton>
                                </ListItem>
                            </>
                        )}
                    </List>
                </Box>
            </SwipeableDrawer>
        </>
    );
}

export default BurgerMenu;
