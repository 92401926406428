import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

type Props = {
    headerMessage: string;
    bodyMessage: string;
    open: boolean;
    closeDialog: () => void;
    confirmFunction: () => void;
}

const DialogProvider = ({ headerMessage, bodyMessage, open, closeDialog, confirmFunction }: Props) =>
{
    return <Dialog
            open={open}
            onClose={() => closeDialog()}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {headerMessage}
            </DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {bodyMessage}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => closeDialog()}>Nein, beibehalten</Button>
                <Button onClick={() => confirmFunction()} autoFocus>
                    Ja, löschen
                </Button>
            </DialogActions>
        </Dialog>
}

export default DialogProvider;
