import { CircularProgress, Grid } from '@mui/material';
import { Container } from '@mui/system';
import { ReactNode } from 'react';

type Props = {
    children: ReactNode[]|ReactNode;
    loaded: boolean;
}

const BusyIndicator = ({ loaded, children }: Props) => {
    return loaded
        ? <>{children}</>
        : <Container maxWidth={"xl"} sx={{ paddingTop: 5}}>
            <Grid container spacing={2}>
                <Grid item xs={12} textAlign={'center'}>
                    <CircularProgress />
                </Grid>
            </Grid>
        </Container>
}

export default BusyIndicator;
