import { Alert, Box, Button } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import TokenContext from '../Contexts/TokenContext';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import InputField from '../Components/InputField/InputField';
import Shop from '../Types/Shop';

type Props = {
    shop?: Shop;
}

function ShopForm({ shop }: Props) {
    let navigate = useNavigate();
    let tokenContext = useContext(TokenContext);
    let [errorMessage, setErrorMessage] = useState<string|null>(null);

    let [shopName, setShopName] = useState<string>();
    let [streetNr, setStreetNr] = useState<string>();
    let [zip, setZip] = useState<string>();
    let [city, setCity] = useState<string>();

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        if (shop) {
            setShopName(shop.name);
            setStreetNr(shop.street_nr);
            setZip(shop.zip);
            setCity(shop.city);
        }
        setLoading(false);
    }, [shop]);

    const createStore = () => {
        if (tokenContext?.token) {
            const data = {
                name: shopName,
                street_nr: streetNr,
                zip,
                city,
            }

            axios.post('/api/shops', data, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response: AxiosResponse) => {
                if (response?.status === 201) {
                    if (response.data.id) {
                        navigate('/shops');
                    }
                }

                setErrorMessage("Upsi! Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche den Login erneut.");
            }).catch((error: AxiosError) => {
                setErrorMessage(error.message);
            });
        }
    }

    const updateStore = () => {
        if (tokenContext?.token && shop) {
            const data = {
                name: shopName,
                street_nr: streetNr,
                zip,
                city,
            }

            axios.put(`/api/shops/${shop.id}`, data, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response: AxiosResponse) => {
                if (response?.status === 200) {
                    if (response.data.id) {
                        navigate('/shops');
                    }
                }

                setErrorMessage("Upsi! Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche den Login erneut.");
            }).catch((error: AxiosError) => {
                setErrorMessage(error.message);
            });
        }
    }

    return loading ? <></> : <>
        {errorMessage && (
            <Alert severity="error">{errorMessage}</Alert>
        )}
        <Box sx={{ mt: 1 }}>
            <InputField
                id='shopname'
                label='Shop-Name:'
                value={shopName ?? ''}
                style={{ my: 1 }}
                setValue={(value) => setShopName(value)}
            />
            <InputField
                id='street_nr'
                label='Straße / Hausnummer:'
                value={streetNr ?? ''}
                style={{ my: 1 }}
                setValue={(value) => setStreetNr(value)}
            />
            <InputField
                id='zip'
                label='PLZ:'
                value={zip ?? ''}
                style={{ my: 1 }}
                setValue={(value) => setZip(value)}
            />
            <InputField
                id='city'
                label='Stadt:'
                value={city ?? ''}
                style={{ my: 1 }}
                setValue={(value) => setCity(value)}
            />
            <Button
                onClick={shop ? updateStore : createStore}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                >
                {shop ? "Speichern" : "Anlegen" }
            </Button>
        </Box>
    </>
}

export default ShopForm;
