import { Avatar, Box } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { useContext, useEffect, useState } from 'react';
import Headline from '../../Components/Headline/Headline';
import Content from '../../Components/Content/Content';
import ShopForm from '../../Forms/ShopForm';
import Shop from '../../Types/Shop';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import TokenContext from '../../Contexts/TokenContext';
import BusyIndicator from '../../Components/BusyIndicator/BusyIndicator';

function Edit() {
    const { id } = useParams();
    const tokenContext = useContext(TokenContext);
    const [shop, setShop] = useState<Shop>();
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        axios.get(`/api/shops/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response) => {
            if (response.status === 200) {
                setShop(response.data);
                setLoaded(true);
            }
        })
    }, [id, tokenContext]);

    return <BusyIndicator loaded={loaded}>
        <Content>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <StorefrontIcon />
                </Avatar>
                <Headline size="h5">Shop bearbeiten</Headline>
                <ShopForm shop={shop} />
            </Box>
        </Content>
    </BusyIndicator>
}

export default Edit;
