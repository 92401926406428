import { Typography } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
    children: string|ReactNode;
    size: 'h1'|'h2'|'h3'|'h4'|'h5'|'h6';
    secondary?: boolean;
}

function Headline({children, size, secondary }: Props) {
    return <Typography component={size} variant={size} color={`text.${secondary ? 'secondary' : 'primary'}`}>
        {children}
    </Typography>
}

export default Headline;
