import { Divider, Grid, IconButton, List, ListItem, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import TokenContext from "../../Contexts/TokenContext";
import Synonym from "../../Types/Synonym";
import InputField from "../InputField/InputField";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import BusyIndicator from "../BusyIndicator/BusyIndicator";

type Props = {
    articleId: number;
}

const SynonymList = ({ articleId }: Props) => {
    let tokenContext = useContext(TokenContext);
    let [synonym, setSynonym] = useState<string>("");
    let [synonyms, setSynonyms] = useState<Synonym[]>([]);
    let [loaded, setLoaded] = useState(false);

    const fetchSynonyms = () => {
        axios.get('/api/articles/' + articleId + '/synonyms', {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response) => {
            if (response.status === 200) {
                setSynonyms(response.data);
                setLoaded(true);
            }
        })
    }

    useEffect(() => {
        if (!loaded && synonyms.length === 0) {
            fetchSynonyms();
        }
    }, [synonyms]);

    const createSynonym = () => {
        setLoaded(false);
        axios.post('/api/articles/' + articleId + '/synonyms', { synonym }, {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response) => {
            if (response.status === 201) {
                setSynonym("");
                setSynonyms([]);
            }
        });
    }

    const deleteSynonym = (synonym: Synonym) => {
        setLoaded(false);
        axios.delete('/api/articles/' + articleId + '/synonyms/' + synonym.id, {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).finally(() => setSynonyms([]));
    }

    return <Grid container>
        <Grid item xs={8}>
            <InputField id="synonym" label="Name auf Bon" setValue={(value) => setSynonym(value)} value={synonym} />
        </Grid>
        <Grid item xs={4}>
            <IconButton size='large' color='primary' onClick={createSynonym}><AddIcon /></IconButton>
        </Grid>
        <Grid item xs={12}>
            <Divider sx={{mt: 2}} />
            <BusyIndicator loaded={loaded}>
                { synonyms.length > 0
                    ? <List>
                        {synonyms.map((synonym, index: number) =>
                            <div key={index}>
                                <ListItem
                                    secondaryAction={
                                        <IconButton
                                            onClick={() => deleteSynonym(synonym)}
                                            edge="end"
                                            aria-label="comments">
                                            <DeleteIcon />
                                        </IconButton>
                                    }>
                                    {synonym.synonym}
                                </ListItem>
                                <Divider />
                            </div>
                        )}
                    </List>
                    : <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
                        Keine Namen eingetragen
                    </Typography>
                }
            </BusyIndicator>
        </Grid>
    </Grid>
}

export default SynonymList;
