import { createContext } from "react";
import SnackBarMessageContextType from "../Types/SnackBarMessageContextType";

const SnackBarMessageContext = createContext<SnackBarMessageContextType>({
    message: "",
    setMessage: (value: string) => null,
    severity: "info",
    setSeverity: (severity: "success"|"error"|"warning"|"info") => null,
    openSnackBar: () => null,
    closeSnackBar: () => null,
});
export default SnackBarMessageContext;
