import { Outlet } from 'react-router-dom';
import AppBar from '../../Components/AppBar/AppBar';
import Content from '../../Components/Content/Content';

function Home() {
    return <>
        <AppBar />
        <Content>
            <Outlet />
        </Content>
    </>
}

export default Home;
