import { Card, CardContent, Typography } from "@mui/material";
import Article from "../../Types/Article";

type Props = {
    article: Article;
}

const ArticleCard = ({ article }: Props) => {
    return <Card>
        <CardContent>
            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
                Marke: {article.brand?.name ?? '-'}
            </Typography>
            <Typography variant="h5" component="div">
                {article.name}
            </Typography>
            <Typography sx={{ mb: 1.5 }} color="text.secondary">
                {article.category?.name}
            </Typography>
        </CardContent>
    </Card>
}

export default ArticleCard;
