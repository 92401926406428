import { Container, Grid } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import TokenContext from '../../Contexts/TokenContext';
import Content from '../../Components/Content/Content';
import Headline from '../../Components/Headline/Headline';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SnackBarMessageContext from '../../Contexts/SnackBarMessageContext';
import Brand from '../../Types/Brand';
import ResponsiveButton from '../../Components/ResponsiveButton/ResponsiveButton';
import BusyIndicator from '../../Components/BusyIndicator/BusyIndicator';
import DialogProvider from '../../Components/DialogProvider/DialogProvider';
import ResponsiveTable from '../../Components/ResponsiveTable/ResponsiveTable';

function Index()
{
    let navigate = useNavigate();
    let tokenContext = useContext(TokenContext);
    let snackbarContext = useContext(SnackBarMessageContext);
    let [brands, setBrands] = useState<Brand[]>([]);
    let [loaded, setLoaded] = useState(false);
    let [dialogOpen, setDialogOpen] = useState<boolean>(false);
    let [brandToDelete, setBrandToDelete] = useState<Brand|null>(null);

    useEffect(() => {
        if (tokenContext?.token && !loaded) {
            axios.get('/api/brands', {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response) => {
                if (response.status === 200) {
                    setBrands(response.data);
                    setLoaded(true);
                }
            })
        }
    }, [loaded, tokenContext]);

    const deleteBrand = (brand: Brand|null) => {
        if (brand) {
            axios.delete(`/api/brands/${brand.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response) => {
                if (response.status === 204) {
                    setBrandToDelete(null);
                    setLoaded(false);
                    setDialogOpen(false)
                }
            }).catch((error) => {
                snackbarContext.setMessage("Die Marke konnte nicht gelöscht werden. Bitte lade die Seite neu und probiere es erneut.");
                snackbarContext.setSeverity("error");
                snackbarContext.openSnackBar();
            })
        }
    }

    const handleDeleteButtonClick = (brand: Brand) => {
        setBrandToDelete(brand);
        setDialogOpen(true);
    }

    return <Content>
            <DialogProvider
                headerMessage='Achtung!'
                bodyMessage='Möchten Sie die Marke wirklich löschen?'
                open={dialogOpen}
                closeDialog={() => setDialogOpen(false)}
                confirmFunction={() => deleteBrand(brandToDelete)}
            />
            <Container maxWidth={'xl'} sx={{paddingTop: 5}}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Headline size='h5'>Marken</Headline>
                </Grid>
                <Grid item xs={6} textAlign={'right'}>
                    <ResponsiveButton
                        value={'Neue Marke'}
                        icon={<AddIcon />}
                        onClick={() => navigate('/brands/add')} />
                </Grid>
                <Grid item xs={12}>
                    <BusyIndicator loaded={loaded}>
                        <ResponsiveTable data={brands}
                                         handleDeleteButtonClick={handleDeleteButtonClick}
                                         routePath={'brands'} />
                    </BusyIndicator>
                </Grid>
            </Grid>
        </Container>
    </Content>
}

export default Index;
