import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import TokenContext from '../../Contexts/TokenContext';

function Logout() {
    let tokenContext = useContext(TokenContext);
    let navigate = useNavigate();
    useEffect(() => {
        if (tokenContext.setToken) {
            tokenContext.setToken(null);
        }
        navigate('/login');
    });

    return <></>
}

export default Logout;
