import { Alert, Autocomplete, Box, Button, TextField } from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import TokenContext from '../Contexts/TokenContext';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useNavigate } from 'react-router-dom';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Shop from '../Types/Shop';
import moment from 'moment';
import InputField from '../Components/InputField/InputField';

function ReceiptForm() {
    let navigate = useNavigate();
    let tokenContext = useContext(TokenContext);
    let [errorMessage, setErrorMessage] = useState<string|null>(null);
    let [receiptDate, setReceiptDate] = useState<string|null>(null);
    let [shops, setShops] = useState<Shop[]>([]);
    let [shopId, setShopId] = useState<number|null>(null);
    let [receiptNumber, setReceiptNumber] = useState<string>();

    let refReceiptDate = useRef<HTMLInputElement>(null);

    useEffect(() => {
        axios.get('/api/shops', {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response: AxiosResponse) => {
            if (response?.status === 200) {
                setShops(response.data);
            } else {
                setErrorMessage("Upsi! Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche den Login erneut.");
            }
        }).catch((error: AxiosError) => {
            setErrorMessage(error.message);
        });
    }, [tokenContext]);

    const createReceipt = () => {
        if (tokenContext?.token) {
            let date = refReceiptDate?.current?.value;
            let momentDate = moment(date, 'DD-MM-YYYY HH:mm');

            const data = {
                receipt_number: receiptNumber,
                receipt_date: momentDate.format('YYYY-MM-DD HH:mm'),
                shop_id: shopId,
            }

            axios.post('/api/receipts', data, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response: AxiosResponse) => {
                if (response?.status === 201) {
                    if (response.data.id) {
                        navigate('/receipts/' + response.data.id);
                    }
                }

                setErrorMessage("Upsi! Da ist etwas schief gelaufen. Bitte lade die Seite neu und versuche den Login erneut.");
            }).catch((error: AxiosError) => {
                setErrorMessage(error.message);
            });
        }
    }


    return <>
        {errorMessage && (
            <Alert severity="error">{errorMessage}</Alert>
        )}
        <Box sx={{ mt: 1 }}>
            <InputField
                id='receipt_number'
                label='Beleg-Nr:'
                value={receiptNumber ?? ''}
                style={{ my: 1 }}
                setValue={(value) => setReceiptNumber(value)}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                    label="Beleg-Datum"
                    value={receiptDate}
                    onChange={setReceiptDate}
                    ampm={false}
                    sx={{ width: '100%', mb: 1 }}
                    format={'DD.MM.YYYY HH:mm'}
                    inputRef={refReceiptDate}
                />
            </LocalizationProvider>
            <Autocomplete
                id="shop_id"
                options={shops}
                getOptionLabel={(option) => option.name }
                onChange={(event, value) => {
                    setShopId(value ? value.id : null);
                }}
                renderInput={(params) => <TextField sx={{ width: '100%', mY: 1 }} {...params} label="Shop" />}
            />
            <Button
                onClick={createReceipt}
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                Anlegen
            </Button>
        </Box>
    </>
}

export default ReceiptForm;
