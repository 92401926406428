import { Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import TokenContext from '../../Contexts/TokenContext';
import { useNavigate } from 'react-router-dom';
import Content from '../../Components/Content/Content';
import Headline from '../../Components/Headline/Headline';
import { Container } from '@mui/system';
import ResponsiveButton from '../../Components/ResponsiveButton/ResponsiveButton';
import BusyIndicator from '../../Components/BusyIndicator/BusyIndicator';
import ResponsiveTable from '../../Components/ResponsiveTable/ResponsiveTable';
import Receipt from '../../Types/Receipt';
import SnackBarMessageContext from '../../Contexts/SnackBarMessageContext';
import DialogProvider from '../../Components/DialogProvider/DialogProvider';

function Index() {
    let navigate = useNavigate();
    let tokenContext = useContext(TokenContext);
    let snackbarContext = useContext(SnackBarMessageContext);
    let [receipts, setReceipts] = useState([]);
    let [loaded, setLoaded] = useState(false);
    let [receiptToDelete, setReceiptToDelete] = useState<Receipt|null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const deleteReceipt = useCallback(() => {
        if (receiptToDelete) {
            axios.delete(`/api/receipts/${receiptToDelete.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response) => {
                if (response.status === 204) {
                    setReceiptToDelete(null);
                    setLoaded(false);
                    setOpen(false);
                    snackbarContext.setMessage("Der Beleg wurde gelöscht.");
                    snackbarContext.setSeverity("success");
                    snackbarContext.openSnackBar();
                }
            }).catch((error) => {
                snackbarContext.setMessage("Der Beleg konnte nicht gelöscht werden. Bitte lade die Seite neu und probiere es erneut.");
                snackbarContext.setSeverity("error");
                snackbarContext.openSnackBar();
            })
        }
    }, [receiptToDelete, tokenContext, snackbarContext]);

    useEffect(() => {
        if (tokenContext?.token && !loaded) {
            axios.get('/api/receipts', {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response) => {
                if (response.status === 200) {
                    setReceipts(response.data);
                    setLoaded(true);
                }
            })
        }
    }, [loaded, tokenContext]);

    const handleDeleteButtonClick = (receipt: Receipt) => {
        setReceiptToDelete(receipt);
        setOpen(true);
    }

    return <Content>
        <DialogProvider bodyMessage='Möchten Sie den Beleg wirklich löschen?'
                        headerMessage='Achtung!'
                        open={open}
                        confirmFunction={() => deleteReceipt()}
                        closeDialog={() => setOpen(false)} />
        <Container maxWidth={'xl'} sx={{paddingTop: 5}}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Headline size='h5'>Belege</Headline>
                </Grid>
                <Grid item xs={6} textAlign={'right'}>
                    <ResponsiveButton
                        value={'Beleg erfassen'}
                        icon={<AddIcon />}
                        onClick={() => navigate('/receipts/add')} />
                </Grid>
                <Grid item xs={12}>
                    <BusyIndicator loaded={loaded}>
                        <ResponsiveTable data={receipts}
                                        handleDeleteButtonClick={handleDeleteButtonClick}
                                        routePath={'receipts'} />
                    </BusyIndicator>
                </Grid>
            </Grid>
        </Container>
    </Content>
}

export default Index;
