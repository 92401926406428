import { Container, Divider, Grid } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import BusyIndicator from '../../Components/BusyIndicator/BusyIndicator';
import Content from '../../Components/Content/Content';
import TokenContext from '../../Contexts/TokenContext';
import Receipt from '../../Types/Receipt';
import ReceiptPositions from '../../Components/ReceiptPositions/ReceiptPositions';
import ReceiptPositionForm from '../../Forms/ReceiptPositionForm';
import ReceiptCard from '../../Components/ReceiptCard/ReceiptCard';

function Edit()
{
    let tokenContext = useContext(TokenContext);

    const [loaded, setLoaded] = useState<boolean>(false);
    const [loadedPositions, setLoadedPositions] = useState<boolean>(false);
    const [receipt, setReceipt] = useState<Receipt|null>(null);

    const { id } = useParams();

    useEffect(() => {
        axios.get('/api/receipts/' + id, {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response) => {
            if (response.status === 200) {
                setReceipt(response.data);
                setLoaded(true);
                setLoadedPositions(true);
            }
        })
    }, [id, tokenContext]);

    useEffect(() => {
        setLoadedPositions(true);
    }, [receipt]);

    return <BusyIndicator loaded={loaded}>
        <Content>
            <Container maxWidth={'md'} sx={{ pt: 5 }}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        {receipt && <ReceiptCard receipt={receipt} />}
                    </Grid>
                    <Grid item xs={12}>
                        <ReceiptPositionForm receiptId={Number(id)} setReceiptHandler={(newReceipt: Receipt) => {
                            setLoadedPositions(false);
                            setReceipt(newReceipt);
                        }} />
                        <Divider sx={{ mt: 1, mb: 3 }} />
                        <BusyIndicator loaded={loadedPositions}>
                            <ReceiptPositions positions={receipt?.positions ?? []} />
                        </BusyIndicator>
                    </Grid>
                </Grid>
            </Container>
        </Content>
    </BusyIndicator>
}

export default Edit;
