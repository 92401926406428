import { useState } from "react";
import {
  createBrowserRouter,
  redirect,
  RouterProvider
} from "react-router-dom";
import Box from "@mui/material/Box"
import TokenContext from "./Contexts/TokenContext";
import axios, { AxiosError } from "axios";
import SnackBarMessageContext from "./Contexts/SnackBarMessageContext";
import { Alert, Snackbar } from "@mui/material";

// Home
import Home from "./Pages/Home/Home";

// Auth
import Login from "./Pages/Auth/Login";
import Logout from "./Pages/Auth/Logout";

// Articles
import ArticleIndex from "./Pages/Articles/Index";
import ArticleCreate from "./Pages/Articles/Create";
import ArticleEdit from "./Pages/Articles/Edit";

// Shops
import ShopIndex from "./Pages/Shops/Index";
import ShopCreate from "./Pages/Shops/Create";
import ShopEdit from "./Pages/Shops/Edit";

// Receipts
import ReceiptIndex from "./Pages/Receipts/Index";
import ReceiptCreate from "./Pages/Receipts/Create";
import ReceiptEdit from "./Pages/Receipts/Edit";

// Brands
import BrandIndex from './Pages/Brands/Index';
import BrandCreate from './Pages/Brands/Create';
import BrandEdit from './Pages/Brands/Edit';

// Categories
import CategoryIndex from './Pages/Categories/Index';
import CategoryCreate from './Pages/Categories/Create';
import CategoryEdit from './Pages/Categories/Edit';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
    children: [
      {
        path: "articles",
        children: [
          {
            path: '',
            element: <ArticleIndex />,
          },
          {
            path: 'add',
            element: <ArticleCreate />
          },
          {
            path: ':id',
            element: <ArticleEdit />
          }
        ]
      },
      {
        path: "shops",
        children: [
          {
            path: '',
            element: <ShopIndex />,
          },
          {
            path: 'add',
            element: <ShopCreate />,
          },
          {
            path: ':id',
            element: <ShopEdit />,
          }
        ]
      },
      {
        path: "/login",
        element: <Login />
      },
      {
        path: "/logout",
        element: <Logout />
      },
      {
        path: 'receipts',
        children: [
          {
            path: '',
            element: <ReceiptIndex />
          },
          {
            path: 'add',
            element: <ReceiptCreate />
          },
          {
            path: ':id',
            element: <ReceiptEdit />
          }
        ]
      },
      {
        path: 'brands',
        children: [
          {
            path: '',
            element: <BrandIndex />
          },
          {
            path: 'add',
            element: <BrandCreate />
          },
          {
            path: ':id',
            element: <BrandEdit />
          }
        ]
      },
      {
        path: 'categories',
        children: [
          {
            path: '',
            element: <CategoryIndex />
          },
          {
            path: 'add',
            element: <CategoryCreate />
          },
          {
            path: ':id',
            element: <CategoryEdit />
          }
        ]
      }
    ]
  }
]);

axios.defaults.baseURL = process.env.REACT_APP_BASE_URL;

axios.interceptors.response.use(undefined, (error: AxiosError) => {
  if (error.response?.status === 401) {
    redirect('/logout');
  }
});

const App = () => {
  const getPersistentToken = () => {
    return sessionStorage.getItem('auth_token');
  }

  const setPersistentToken = (token: string|null) => {
    if (token === null) {
      sessionStorage.removeItem('auth_token');
    } else {
      sessionStorage.setItem('auth_token', token);
    }
    setToken(token);
  }

  let [token, setToken] = useState<string|null>(getPersistentToken());
  let [snackBarMessage, setSnackBarMessage] = useState<string>("")
  let [snackBarOpen, toggleSnackBarVisibleState] = useState<boolean>(false);
  let [severity, setSeverity] = useState<"success"|"error"|"warning"|"info">("info");

  const openSnackBar = () => toggleSnackBarVisibleState(true);
  const closeSnackBar = () => toggleSnackBarVisibleState(false);

  return <Box>
    <Snackbar anchorOrigin={{ vertical: "top", horizontal: "center" }} open={snackBarOpen} autoHideDuration={6000} onClose={closeSnackBar}>
      <Alert onClose={closeSnackBar} severity={severity} sx={{ width: '100%' }}>
        {snackBarMessage}
      </Alert>
    </Snackbar>
    <SnackBarMessageContext.Provider value={{ message: snackBarMessage, setMessage: setSnackBarMessage, openSnackBar, closeSnackBar, setSeverity, severity }}>
      <TokenContext.Provider value={{token, setToken: setPersistentToken}}>
        <RouterProvider router={router} />
      </TokenContext.Provider>
    </SnackBarMessageContext.Provider>
  </Box>
};

export default App;
