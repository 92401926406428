import { Avatar, Box } from '@mui/material';
import StorefrontIcon from '@mui/icons-material/Storefront';
import React from 'react';
import Headline from '../../Components/Headline/Headline';
import Content from '../../Components/Content/Content';
import ShopForm from '../../Forms/ShopForm';

function Create() {
    return <Content>
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <StorefrontIcon />
            </Avatar>
            <Headline size="h5">Shop anlegen</Headline>
            <ShopForm />
        </Box>
    </Content>
}

export default Create;
