import { useContext, useEffect, useRef, useState } from 'react';
import { Divider, Grid, IconButton, List, ListItem, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import BarcodeScanner, { BarcodeScannerRef } from '../../Components/BarcodeScanner/BarcodeScanner';
import InputField from '../../Components/InputField/InputField';
import TokenContext from '../../Contexts/TokenContext';
import axios, { AxiosResponse } from 'axios';
import EanCode from '../../Types/EanCode';
import BusyIndicator from '../BusyIndicator/BusyIndicator';

type Props = {
    articleId: number;
}

const EanCodeList = ({ articleId }: Props) => {
    const barcodeScannerRef = useRef<BarcodeScannerRef>();
    const tokenContext = useContext(TokenContext);
    const [eans, setEans] = useState<EanCode[]>([]);
    const [ean, setEan] = useState<string>();
    const [isScanViewOpen, setIsScanViewOpen] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [scanError, setScanError] = useState();

    const fetchEans = () => {
        axios.get('/api/articles/' + articleId + '/ean', {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response) => {
            if (response.status === 200) {
                setEans(response.data);
                setLoaded(true);
            }
        })
    }

    useEffect(() => {
        if (!loaded && eans.length === 0) {
            fetchEans();
        }
    }, [loaded, eans]);

    const createEancode = () => {
        setLoaded(false);

        const data = { ean_code: ean };
        axios.post('/api/articles/' + articleId + '/ean', data, {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response: AxiosResponse) => {
            if (response.status === 201) {
                setEan(undefined);
                setEans([])
            }
        });
    }

    const handleScanError = (error: any) => {
        setScanError(error);
    }

    const handleScanCode = (decodedText: string) => {
        setEan(decodedText);
        setIsScanViewOpen(false);
    }

    const scanEanCode = () => {
        if (isScanViewOpen) {
            barcodeScannerRef.current?.stop();
        }

        setIsScanViewOpen(!isScanViewOpen);
    }

    const deleteEan = (ean: EanCode) => {
        setLoaded(true);
        axios.delete('/api/articles/' + articleId + '/ean/' + ean.id, {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response) => {
            if (response.status === 204) {
                setLoaded(false);
            }
        }).finally(() => setEans([]));
    }

    return <Grid container>
        <Grid item xs={8}>
            <InputField id="ean" label="EAN-Code" setValue={(value) => setEan(value)} value={ean ?? ''} />
        </Grid>
        <Grid item xs={2}>
            <IconButton size='large' color='primary' onClick={createEancode}><AddIcon /></IconButton>
        </Grid>
        <Grid item xs={2}>
            <IconButton size='large' color='primary' onClick={scanEanCode}><QrCodeScannerIcon /></IconButton>
        </Grid>
        <Grid item xs={12}>
            <Divider sx={{mt: 2}} />
            <BusyIndicator loaded={loaded}>
                {eans.length > 0
                    ? !isScanViewOpen ? <List>
                        {eans.map((eanCode, index: number) =>
                            <div key={index}>
                                <ListItem
                                    secondaryAction={
                                        <IconButton
                                            onClick={() => deleteEan(eanCode)}
                                            edge="end"
                                            aria-label="comments">
                                            <DeleteIcon />
                                        </IconButton>
                                    }>
                                    {eanCode.ean_code}
                                </ListItem>
                                <Divider />
                            </div>
                        )}
                    </List> : <BarcodeScanner ref={barcodeScannerRef} onError={handleScanError} onScan={handleScanCode} />
                    : <Typography variant="body1" sx={{ mt: 2 }} gutterBottom>
                        Keine Barcodes eingetragen
                    </Typography>
                }
            </BusyIndicator>
        </Grid>
    </Grid>
}

export default EanCodeList;
