import { FormControl, FormHelperText, InputLabel, OutlinedInput, SxProps, Input } from '@mui/material';
import NumericInput from 'material-ui-numeric-input';

type Props = {
    id: string;
    label: string;
    value: string|number;
    setValue: (value: string) => void;
    helpText?: string;
    type?: 'password'|'text'|'number';
    asInteger?: boolean;
    style?: SxProps;
    textarea?: boolean;
    outlined?: boolean;
}

function InputField({ id, label, helpText, setValue, value, type, asInteger, style, textarea, outlined }: Props) {
    return <FormControl fullWidth sx={style}>
        {type !== 'number' && <InputLabel htmlFor={id}>{label}</InputLabel>}
        {type === 'number' ?
            <NumericInput
                label={label}
                aria-describedby={id + "-helper-text"}
                id={id}
                value={value ?? undefined}
                precision={asInteger ? 0 : 2}
                thousandChar='.'
                decimalChar=','
                onChange={(e) => setValue(e.target.value?.toString() ?? '')}
                variant={outlined ? 'outlined' : 'standard'} />
        : (outlined
            ? <OutlinedInput label={label}
                       id={id}
                       type={type ?? 'text'}
                       aria-describedby={id + "-helper-text"}
                       value={value ?? ''}
                       multiline={textarea ?? false}
                       maxRows={textarea ? 15 : undefined}
                       minRows={textarea ? 4 : undefined}
                       onChange={(e) => setValue(e.target.value)} />
            : <Input
                    id={id}
                    type={type ?? 'text'}
                    aria-describedby={id + "-helper-text"}
                    value={value ?? ''}
                    multiline={textarea ?? false}
                    maxRows={textarea ? 15 : undefined}
                    minRows={textarea ? 4 : undefined}
                    onChange={(e) => setValue(e.target.value)} />
        )}
        {helpText && <FormHelperText id={id + "-helper-text"}>{helpText}</FormHelperText>}
    </FormControl>
}

export default InputField;
