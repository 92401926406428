import { Avatar } from '@mui/material';
import { Box } from '@mui/system';
import { useContext, useEffect, useState } from 'react';
import FactoryIcon from '@mui/icons-material/Factory';
import Headline from '../../Components/Headline/Headline';
import Content from '../../Components/Content/Content';
import BrandForm from '../../Forms/BrandForm';
import { useParams } from 'react-router-dom';
import TokenContext from '../../Contexts/TokenContext';
import Brand from '../../Types/Brand';
import axios, { AxiosResponse } from 'axios';
import BusyIndicator from '../../Components/BusyIndicator/BusyIndicator';

function Edit()
{
    const { id } = useParams();
    let tokenContext = useContext(TokenContext);
    const [brand, setBrand] = useState<Brand>();
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        axios.get(`/api/brands/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response: AxiosResponse) => {
            if (response.status === 200) {
                setBrand(response.data);
                setLoaded(true);
            }
        });
    }, [id, tokenContext]);

    return <BusyIndicator loaded={loaded}>
        <Content>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <FactoryIcon />
                </Avatar>
                <Headline size="h5">Bearbeiten</Headline>
                <BrandForm brand={brand} />
            </Box>
        </Content>
    </BusyIndicator>
}

export default Edit;
