import { Button, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { CameraDevice } from 'html5-qrcode/esm/camera/core';
import { Html5Qrcode } from 'html5-qrcode/esm/html5-qrcode';
import { forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState } from 'react';
import SnackBarMessageContext from '../../Contexts/SnackBarMessageContext';

type Props = {
    onError: (error: any) => void;
    onScan: (result: string) => void;
}

export type BarcodeScannerRef = {
  stop: () => void;
}

const BarcodeScanner = forwardRef(({ onError, onScan }: Props, outerRef) => {
  const ref = useRef<HTMLDivElement>(null);
  const snackbar = useContext(SnackBarMessageContext);
  const [scanStarted, setScanStarted] = useState<boolean>(false);
  const [cameras, setCameras] = useState<CameraDevice[]>([]);
  const [camera, setCamera] = useState<CameraDevice>();
  const [scanner, setScanner] = useState<Html5Qrcode>();

  useImperativeHandle(outerRef, () => ({
    stop() {
      if (scanner?.isScanning) {
        scanner.stop();
      }
    }
  }));

  useEffect(() => {
    Html5Qrcode.getCameras().then(setCameras).catch(err => {
      snackbar.setSeverity("error");
      snackbar.setMessage(err);
      snackbar.openSnackBar();
    });
  }, []);

  useEffect(() => {
    if (camera && ref.current) {
      setScanner(new Html5Qrcode(ref.current.id));
    }
  }, [camera, ref]);

  useEffect(() => {
    if (scanner && camera && scanStarted) {
      scanner.start(
        camera.id,
        {
          fps: 10,    // Optional, frame per seconds for qr code scanning
          qrbox: { width: 250, height: 250 }  // Optional, if you want bounded box UI
        },
        (decodedText, decodedResult) => {
          // do something when code is read
          onScan(decodedText);
        },
        (errorMessage) => {
          // parse error, ignore it.
          onError(errorMessage);
        })
      .catch((err) => {
        // Start failed, handle it.
        onError(err);
      });
    } else if (scanner && !scanStarted) {
      if (scanner.isScanning) {
        scanner.stop();
      }
    }
  }, [scanner, camera, scanStarted]);

  return <>
    <div id="barcode-reader" ref={ref}></div>
    {scanStarted
      ? <>
        <Button onClick={() => setScanStarted(false)}>Scan stoppen</Button>
      </>
      : <>
        <FormControl fullWidth sx={{my: 2}}>
          <InputLabel id="camera-devices-label">Kamera</InputLabel>
          <Select
            labelId="camera-devices-label"
            id="camera-devices"
            value={camera?.id.toString() ?? ""}
            label="Kamera"
            onChange={(event: SelectChangeEvent<string>) => setCamera(cameras.find((item: CameraDevice) => item.id == event.target.value))}
          >
            <MenuItem value={""}>Kamera wählen</MenuItem>
            {cameras.map((item: CameraDevice, index: number) =>
              <MenuItem key={index} value={item.id}>{item.label}</MenuItem>
            )}
          </Select>
        </FormControl>

        <Button onClick={() => setScanStarted(true)}>Kamera wählen & Scan starten</Button>
      </>
    }
  </>;
});

export default BarcodeScanner;
