import { Avatar, Box } from '@mui/material';
import Content from '../../Components/Content/Content';
import ArticleIcon from '@mui/icons-material/Article';
import Headline from '../../Components/Headline/Headline';
import ArticleForm from '../../Forms/ArticleForm';

function Create()
{
    return <Content>
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <ArticleIcon />
            </Avatar>
            <Headline size="h5">Artikel hinzufügen</Headline>
            <ArticleForm />
        </Box>
    </Content>
}

export default Create;
