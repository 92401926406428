import { IconButton, Paper, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, ListItem, Divider, List, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import IEntity from '../../Types/Interfaces/IEntity';

type Props<T> = {
    data: T[];
    handleDeleteButtonClick: (entity: T) => void;
    routePath: string;
}

const ResponsiveTable = <T extends IEntity>({ data, handleDeleteButtonClick, routePath }: Props<T>) =>
{
    let theme = useTheme();
    let greaterThanMd = useMediaQuery(theme.breakpoints.up('md'));

    return greaterThanMd ?
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((entity: T, index: number) => (
                        <TableRow
                            key={index}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">
                                <Link to={`/${routePath}/${entity.id}`}>
                                    {entity.name ?? entity.receipt_number}
                                </Link>
                            </TableCell>
                            <TableCell align="right">
                                <IconButton aria-label="delete" onClick={() => handleDeleteButtonClick(entity)}>
                                    <DeleteIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
        : (
            <List>
                <>
                    {data.map((entity: T, index: number) =>
                        <div key={index}>
                            <ListItem
                                secondaryAction={
                                    <IconButton aria-label="delete" onClick={() => handleDeleteButtonClick(entity)}>
                                        <DeleteIcon />
                                    </IconButton>
                                }
                            >
                                <Link to={`/${routePath}/${entity.id}`}>
                                    {entity.name ?? entity.receipt_number}
                                </Link>
                            </ListItem>
                            <Divider />
                        </div>
                    )}
                </>
            </List>
        );
}

export default ResponsiveTable;
