import { Autocomplete, Button, FilterOptionsState, Grid, TextField } from '@mui/material';
import {useEffect, useContext, useRef, useState } from 'react';
import BarcodeScanner, { BarcodeScannerRef } from '../Components/BarcodeScanner/BarcodeScanner';
import Article from '../Types/Article';
import EanCode from '../Types/EanCode';
import Synonym from '../Types/Synonym';
import axios from 'axios';
import TokenContext from '../Contexts/TokenContext';
import Receipt from '../Types/Receipt';
import InputField from '../Components/InputField/InputField';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';

type Props = {
    receiptId: number;
    setReceiptHandler: (value: Receipt) => void;
}

const ReceiptPositionForm = ({ receiptId, setReceiptHandler }: Props) => {
    let tokenContext = useContext(TokenContext);

    const barcodeScannerRef = useRef<BarcodeScannerRef>();
    const autocompleteRef = useRef();

    const [isScanViewOpen, setIsScanViewOpen] = useState(false);
    const [articles, setArticles] = useState<Article[]>([]);
    const [quantity, setQuantity] = useState<string>('1');
    const [price, setPrice] = useState<string>('0.00');
    const [deposit, setDeposit] = useState<string|null>(null);
    const [autocompleteValue, setAutocompleteValue] = useState<string>();

    const [selectedArticle, setSelectedArticle] = useState<Article|null>(null);

    useEffect(() => {
        axios.get('/api/articles', {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response) => {
            if (response.status === 200) {
                setArticles(response.data);
            }
        })
    }, []);

    const handleScanError = (error: any) => {}

    const handleScanCode = (decodedText: string) => {
        if (isScanViewOpen) {
            const foundArticles = filterArticlesByValue(articles, decodedText);
            if (foundArticles.length > 0) {
                setSelectedArticle(foundArticles[0]);
                barcodeScannerRef.current?.stop();
            }
        }
        setIsScanViewOpen(false);
    }

    const scanEanCode = () => {
        if (isScanViewOpen) {
            barcodeScannerRef.current?.stop();
        }

        setIsScanViewOpen(!isScanViewOpen);
    }

    const createReceiptPosition = () => {
        const data = {
            article_id: selectedArticle?.id,
            quantity,
            price,
            deposit: deposit === '0.00' ? null : deposit,
        };

        axios.post('/api/receipts/' + receiptId + '/positions', data, {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response) => {
            if (response.status === 201) {
                setReceiptHandler(response.data);
                setSelectedArticle(null);
                setQuantity('1');
                setPrice('0.00');
            }
        })
    }

    const filterArticlesByValue = (articles: Article[], inputValue: string) => articles.filter((art: Article) => {
        if (inputValue === "") {
            return true;
        } else {
            const eanCodes = (art.ean_codes ?? []).map((ean: EanCode) => {
                return ean.ean_code;
            }).join(';');
            const synonyms = (art.synonyms ?? []).map((syn: Synonym) => {
                return syn.synonym;
            }).join(';');
            const searchString = eanCodes + synonyms + art.name + art.brand?.name;
            return searchString.toLowerCase().includes(inputValue.toLowerCase());
        }
    })

    return <>
        <Grid container spacing={2}>
            <Grid item xs={8} sm={10}>
                <Autocomplete
                    ref={autocompleteRef}
                    value={selectedArticle}
                    options={articles}
                    filterOptions={(options: Article[], state: FilterOptionsState<Article>) => filterArticlesByValue(options, state.inputValue)}
                    getOptionLabel={(article) => article.name + ( article.brand ? ' (' + article.brand?.name + ')' : '') }
                    id="article"
                    onChange={(event, value) => {
                        setSelectedArticle(value ? value : null);
                    }}
                    sx={{ width: '100%' }}
                    renderInput={(params) => <TextField {...params} value={autocompleteValue} label="Artikel" />}
                />
            </Grid>
            <Grid item xs={4} sm={2} textAlign={'right'}>
                <Button size='large' sx={{ height: '100%' }} variant='contained' color='primary' onClick={scanEanCode}>
                    <QrCodeScannerIcon />
                </Button>
            </Grid>
            <Grid item xs={12} md={2}>
                <InputField label={'Menge'}
                            value={quantity}
                            outlined
                            type={'number'}
                            asInteger={true}
                            setValue={setQuantity}
                            id={'quantity'} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputField label={'Preis'}
                        outlined
                        type={'number'}
                        value={price}
                        setValue={setPrice}
                        id={'price'} />
            </Grid>
            <Grid item xs={12} md={4}>
                <InputField label={'Pfand'}
                        outlined
                        type={'number'}
                        value={deposit ?? 0.00}
                        setValue={setDeposit}
                        id={'deposit'} />
            </Grid>
            <Grid item xs={12} md={2} textAlign={'right'}>
                <Button sx={{ height: '100%' }}
                    variant='contained'
                    color='primary'
                    onClick={createReceiptPosition}>
                        Speichern
                    </Button>
            </Grid>
            <Grid item xs={12}>
                {isScanViewOpen && <BarcodeScanner ref={barcodeScannerRef} onError={handleScanError} onScan={handleScanCode} />}
            </Grid>
        </Grid>
    </>;
}

export default ReceiptPositionForm;
