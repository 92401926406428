import { Avatar, Box } from '@mui/material';
import Content from '../../Components/Content/Content';
import CategoryIcon from '@mui/icons-material/Category';
import Headline from '../../Components/Headline/Headline';
import CategoryForm from '../../Forms/CategoryForm';

function Create()
{
    return <Content>
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <CategoryIcon />
            </Avatar>
            <Headline size="h5">Kategorie hinzufügen</Headline>
            <CategoryForm />
        </Box>
    </Content>
}

export default Create;
