import { Container, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import { useCallback, useContext, useEffect, useState } from 'react';
import TokenContext from '../../Contexts/TokenContext';
import { useNavigate } from 'react-router-dom';
import Content from '../../Components/Content/Content';
import Headline from '../../Components/Headline/Headline';
import Shop from '../../Types/Shop';
import SnackBarMessageContext from '../../Contexts/SnackBarMessageContext';
import ResponsiveButton from '../../Components/ResponsiveButton/ResponsiveButton';
import BusyIndicator from '../../Components/BusyIndicator/BusyIndicator';
import DialogProvider from '../../Components/DialogProvider/DialogProvider';
import ResponsiveTable from '../../Components/ResponsiveTable/ResponsiveTable';

function Index() {
    let navigate = useNavigate();
    let tokenContext = useContext(TokenContext);
    let snackbarContext = useContext(SnackBarMessageContext);
    let [shops, setShops] = useState([]);
    let [loaded, setLoaded] = useState(false);
    let [shopToDelete, setShopToDelete] = useState<Shop|null>(null);
    const [open, setOpen] = useState<boolean>(false);

    const deleteShop = useCallback(() => {
        if (shopToDelete) {
            axios.delete(`/api/shops/${shopToDelete.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response) => {
                if (response.status === 204) {
                    setShopToDelete(null);
                    setLoaded(false);
                    setOpen(false);
                }
            }).catch((error) => {
                snackbarContext.setMessage("Die Kategorie konnte nicht gelöscht werden. Bitte lade die Seite neu und probiere es erneut.");
                snackbarContext.setSeverity("error");
                snackbarContext.openSnackBar();
            })
        }
    }, [shopToDelete, tokenContext, snackbarContext]);

    useEffect(() => {
        if (tokenContext?.token && !loaded) {
            axios.get('/api/shops', {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response) => {
                if (response.status === 200) {
                    setShops(response.data);
                    setLoaded(true);
                }
            })
        }
    }, [loaded, tokenContext]);

    const handleDeleteButtonClick = (shop: Shop) => {
        setShopToDelete(shop);
        setOpen(true);
    }

    return <Content>
        <DialogProvider bodyMessage='Möchten Sie den Shop wirklich löschen?'
                        headerMessage='Achtung!'
                        open={open}
                        confirmFunction={() => deleteShop()}
                        closeDialog={() => setOpen(false)} />
        <Container maxWidth={'xl'} sx={{paddingTop: 5}}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Headline size='h5'>Shops</Headline>
                </Grid>
                <Grid item xs={6} textAlign={'right'}>
                    <ResponsiveButton
                        value={'Shop hinzufügen'}
                        icon={<AddIcon />}
                        onClick={() => navigate('/shops/add')} />
                </Grid>
                <Grid item xs={12}>
                    <BusyIndicator loaded={loaded}>
                        <ResponsiveTable data={shops}
                                         handleDeleteButtonClick={handleDeleteButtonClick}
                                         routePath={'shops'} />
                    </BusyIndicator>
                </Grid>
            </Grid>
        </Container>
    </Content>
}

export default Index;
