import { Avatar, Box } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import React from 'react';
import LoginForm from '../../Forms/LoginForm';
import Content from '../../Components/Content/Content';
import Headline from '../../Components/Headline/Headline';

function Login() {
    return <Content>
        <Box
            sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Headline size='h5'>Einloggen</Headline>
            <LoginForm />
        </Box>
    </Content>
}

export default Login;
