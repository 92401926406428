import { Container } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
    children: ReactNode;
}

function Content({ children }: Props) {
    return <Container component="main" maxWidth="xl">
        {children}
    </Container>;
}

export default Content;
