const useFormatter = () => {
    const formatter = Intl.NumberFormat("de-DE", {
        currency: "EUR",
        style: "currency"
    });

    return formatter;
}

export default useFormatter;
