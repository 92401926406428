import { Container, Grid } from '@mui/material';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import TokenContext from '../../Contexts/TokenContext';
import Content from '../../Components/Content/Content';
import Headline from '../../Components/Headline/Headline';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SnackBarMessageContext from '../../Contexts/SnackBarMessageContext';
import Category from '../../Types/Category';
import ResponsiveButton from '../../Components/ResponsiveButton/ResponsiveButton';
import BusyIndicator from '../../Components/BusyIndicator/BusyIndicator';
import DialogProvider from '../../Components/DialogProvider/DialogProvider';
import ResponsiveTable from '../../Components/ResponsiveTable/ResponsiveTable';

function Index()
{
    let navigate = useNavigate();
    let tokenContext = useContext(TokenContext);
    let snackbarContext = useContext(SnackBarMessageContext);
    let [categories, setCategories] = useState([]);
    let [loaded, setLoaded] = useState(false);
    let [dialogOpen, setDialogOpen] = useState<boolean>(false);
    let [categoryToDelete, setCategoryToDelete] = useState<Category|null>(null);

    useEffect(() => {
        if (tokenContext?.token && !loaded) {
            axios.get('/api/categories', {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response) => {
                if (response.status === 200) {
                    setCategories(response.data);
                    setLoaded(true);
                }
            }).catch((error) => {
                snackbarContext.setMessage("Die Daten konnten nicht geladen werden. Bitte lade die Seite neu und probiere es erneut.");
                snackbarContext.setSeverity("warning");
                snackbarContext.openSnackBar();
            })
        }
    }, [loaded, tokenContext, snackbarContext]);

    const deleteCategory = (category: Category|null) => {
        if (category) {
            axios.delete(`/api/categories/${category.id}`, {
                headers: {
                    'Authorization': 'Bearer ' + tokenContext.token,
                }
            }).then((response) => {
                if (response.status === 204) {
                    setCategoryToDelete(null);
                    setLoaded(false);
                    setDialogOpen(false)

                    snackbarContext.setMessage("Die Kategorie wurde erfolgreich gelöscht.");
                    snackbarContext.setSeverity("success");
                    snackbarContext.openSnackBar();
                }
            }).catch((error) => {
                snackbarContext.setMessage("Die Kategorie konnte nicht gelöscht werden. Bitte lade die Seite neu und probiere es erneut.");
                snackbarContext.setSeverity("error");
                snackbarContext.openSnackBar();
            })
        }
    }

    const handleDeleteButtonClick = (category: Category) => {
        setCategoryToDelete(category);
        setDialogOpen(true);
    }

    return <Content>
        <DialogProvider
            headerMessage='Achtung!'
            bodyMessage='Möchten Sie die Kategorie wirklich löschen?'
            open={dialogOpen}
            closeDialog={() => setDialogOpen(false)}
            confirmFunction={() => deleteCategory(categoryToDelete)}
        />
        <Container maxWidth={'xl'} sx={{paddingTop: 5}}>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    <Headline size='h5'>Kategorien</Headline>
                </Grid>
                <Grid item xs={6} textAlign={'right'}>
                    <ResponsiveButton
                        value={'Neue Kategorie'}
                        icon={<AddIcon />}
                        onClick={() => navigate('/categories/add')} />
                </Grid>
                <Grid item xs={12}>
                    <BusyIndicator loaded={loaded}>
                        <ResponsiveTable data={categories}
                                         handleDeleteButtonClick={handleDeleteButtonClick}
                                         routePath={'categories'} />
                    </BusyIndicator>
                </Grid>
            </Grid>
        </Container>
    </Content>
}

export default Index;
