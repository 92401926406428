import { Avatar, Box } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import Content from '../../Components/Content/Content';
import CategoryIcon from '@mui/icons-material/Category';
import Headline from '../../Components/Headline/Headline';
import CategoryForm from '../../Forms/CategoryForm';
import { useParams } from 'react-router-dom';
import axios, { AxiosResponse } from 'axios';
import Category from '../../Types/Category';
import TokenContext from '../../Contexts/TokenContext';
import BusyIndicator from '../../Components/BusyIndicator/BusyIndicator';

function Edit()
{
    const { id } = useParams();
    let tokenContext = useContext(TokenContext);
    const [category, setCategory] = useState<Category>();
    const [loaded, setLoaded] = useState<boolean>(false);

    useEffect(() => {
        axios.get(`/api/categories/${id}`, {
            headers: {
                'Authorization': 'Bearer ' + tokenContext.token,
            }
        }).then((response: AxiosResponse) => {
            if (response.status === 200) {
                setCategory(response.data);
                setLoaded(true);
            }
        });
    }, [id, tokenContext]);

    return <BusyIndicator loaded={loaded}>
        <Content>
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <CategoryIcon />
                </Avatar>
                <Headline size="h5">Kategorie bearbeiten</Headline>
                <CategoryForm category={category} />
            </Box>
        </Content>
    </BusyIndicator>
}

export default Edit;
