import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

type Props = {
    appName: string;
}

function AppTitle({ appName }: Props) {
    let navigate = useNavigate();
    return (
        <Typography variant="h6"
                    component="div"
                    sx={{ flexGrow: 1 }}>
            <span style={{ cursor: 'pointer' }}
                  onClick={() => navigate('/')}>{appName}</span>
        </Typography>
    );
}

export default AppTitle;
